import React, { memo } from "react"
import ReactSelect, { GroupBase, OptionProps, Props as SelectProps } from "react-select"
import { IconControl } from "./IconControl"
import { baseStyles } from "./SelectBase"

interface CustomProps {
  small?: boolean
  icon?: React.ReactNode
  invalid?: boolean
}

type Props<
  OptionType extends OptionProps,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
> = CustomProps & SelectProps<OptionType, IsMulti, GroupType>

// Wrapper for react-select that sets the target to document.body and solves some styling
const _Select: React.FC<Props<any, any, any>> = <OptionType extends OptionProps, IsMulti extends boolean>({
  small,
  icon,
  components = {},
  styles = {},
  invalid = false,
  ...rest
}: Props<OptionType, IsMulti>) => {
  if (!!icon) {
    components.Control = IconControl(icon)
  }

  return (
    <ReactSelect
      {...rest}
      menuPortalTarget={document.body}
      data-testid="react-select"
      menuPlacement="auto"
      noOptionsMessage={() => "Kein Treffer"}
      components={components}
      styles={baseStyles<OptionType, IsMulti>(styles, invalid, small)}
    />
  )
}

export default memo(_Select)
