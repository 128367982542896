import Local from "./local"
import Production from "./production"
import Staging from "./staging"

export type EnvironmentConfiguration = typeof Staging
export { Local, Staging, Production }

export default function (): EnvironmentConfiguration {
  if (location.href.indexOf(Production.urlMatch) >= 0) return Production
  if (location.href.indexOf(Staging.urlMatch) >= 0) return Staging
  if (window.localStorage.getItem("dev/environment") === "Local") return Local
  return {
    ...Staging,
    sidebarColor: Local.sidebarColor,
    appChannel: Local.appChannel,
  }
}
