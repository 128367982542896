import React from "react"
import getConfig, { EnvironmentConfiguration } from "./env"
import ApiService from "./modules/app/api/ApiService"
import AuthStore from "./modules/app/model/store/AuthStore"
import OrgUnitStore from "./modules/app/model/store/OrgUnitStore"
import ZsbSorterConfigStore from "./modules/app/model/store/ZsbSorterConfigStore"
import UserNotificationStore from "./modules/app/model/store/UserNotificationStore"
import FeatureToggleStore from "./modules/app/model/store/FeatureToggleStore"

export class AppServiceManager {
  environmentConfig: EnvironmentConfiguration

  apiService: ApiService
  authStore: AuthStore
  userNotificationStore: UserNotificationStore
  orgUnitStore: OrgUnitStore
  featureToggleStore: FeatureToggleStore
  zsbSorterConfigStore: ZsbSorterConfigStore

  constructor(environmentConfig: EnvironmentConfiguration) {
    this.environmentConfig = environmentConfig
    this.authStore = new AuthStore(this.environmentConfig.keycloak)
    this.apiService = new ApiService(this.authStore, this.environmentConfig.apiURL)
    this.userNotificationStore = new UserNotificationStore(this)
    this.orgUnitStore = new OrgUnitStore(this.authStore, this.apiService, this.userNotificationStore)
    this.featureToggleStore = new FeatureToggleStore(this.orgUnitStore, this.apiService, this.userNotificationStore)
    this.zsbSorterConfigStore = new ZsbSorterConfigStore(
      this.authStore,
      this.apiService,
      this.orgUnitStore,
      this.userNotificationStore
    )
  }
}

const serviceManager = new AppServiceManager(getConfig())

export const ServiceManagerContext = React.createContext<AppServiceManager>(serviceManager)

export const ServiceManagerInstance = serviceManager
