import { observer } from "mobx-react-lite"
import React from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import ZsbSorterConfigs from "../../sites/home/ZsbSorterConfigs"

type SettingsModalProps = {
  toggle: () => void
}

const SettingsModal: React.FC<SettingsModalProps> = ({ toggle }: SettingsModalProps) => {
  return (
    <Modal isOpen fullscreen="md" size="lg" toggle={toggle}>
      <ModalHeader toggle={toggle}>Standardeinstellungen</ModalHeader>
      <ModalBody>
        <ZsbSorterConfigs defaultConfig />
      </ModalBody>
    </Modal>
  )
}

export default observer(SettingsModal)
