import { observer } from "mobx-react-lite"
import React, { useContext } from "react"
import logo from "url:./hermes-logo.svg"
import { ServiceManagerContext } from "../../../../AppServiceManager"
import { AppsMenu, BurgerMenu, Popover, Sidebar, UserIndicator } from "@hermesgermany/last-mile-components"
import { getLinkedApps } from "./linkedApps"

const SideNavigation = () => {
  const { environmentConfig, orgUnitStore, authStore } = useContext(ServiceManagerContext)
  const { userProfileNames, accountDetails, logout } = authStore
  const { firstName, lastName, username } = userProfileNames ?? {}
  const selectedOrgIdPath = `/orgs/${orgUnitStore.selectedOrgUnit?.orgId}`
  const backgroundColor = environmentConfig.sidebarColor

  return (
    <Sidebar
      imgTitle="sidebar"
      imgSrc={logo}
      style={{ backgroundColor }}
      bottomComponents={
        <>
          <AppsMenu popoverTitle="Hermes Apps" apps={getLinkedApps(selectedOrgIdPath)} />
          <BurgerMenu>
            <Popover.MenuGroup groupLabel="Kontakt">
              <Popover.MenuItem
                label="zsb-cockpit@hermesworld.com"
                onClick={() => window.open("mailto:zsb-cockpit@hermesworld.com")}
                data-testid="sidebar-help-entry"
              />
            </Popover.MenuGroup>
            <Popover.MenuGroup groupLabel="Konto">
              <Popover.MenuItem label="Mein Profil" onClick={accountDetails}>
                <UserIndicator firstName={firstName} lastName={lastName} username={username} />
              </Popover.MenuItem>
              <Popover.MenuItem label="Abmelden" onClick={logout}></Popover.MenuItem>
            </Popover.MenuGroup>
          </BurgerMenu>
        </>
      }
    />
  )
}

export default observer(SideNavigation)
