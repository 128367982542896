import React from "react"
import { Badge } from "reactstrap"
import ConfigSymbolContainer from "./ConfigSymbolContainer"
import { SettingProps } from "./SettingProps"

const ScanModeBadge = ({ setting, defaultConfig }: SettingProps) => {
  let backgroundColor = undefined
  let badgeLabel = undefined
  const isDefault = !setting?.value
  const currentSetting = setting?.value ?? defaultConfig?.value

  switch (currentSetting) {
    case "SORTING":
      backgroundColor = "#0091CD"
      badgeLabel = "SORT"
      break
    case "SE_SCAN":
      backgroundColor = "#00CC0A"
      badgeLabel = "SE-Scan"
      break
    case "DEMO":
      backgroundColor = "#7F7F7F"
      badgeLabel = "DEMO"
      break
    case "LABEL_PRINT":
      backgroundColor = "#00574B"
      badgeLabel = "DRUCK"
      break
    default:
      break
  }

  return (
    <ConfigSymbolContainer isDefault={isDefault}>
      <Badge color="" style={{ backgroundColor }}>
        {badgeLabel}
      </Badge>
    </ConfigSymbolContainer>
  )
}

export default ScanModeBadge
