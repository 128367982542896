import { makeAutoObservable, runInAction, when } from "mobx"
import ApiService from "../../api/ApiService"
import OrgUnitStore from "./OrgUnitStore"
import UserNotificationStore from "./UserNotificationStore"
import setIntervalDelay from "../../../utils/setIntervalDelay"

const FEATURE_TOGGLE_PREFIX = "featureToggle/"

export enum FeatureToggle {
  SHOW_TOGGLE_TO_ENABLE_PRINTING = "SHOW_TOGGLE_TO_ENABLE_PRINTING",
  SHOW_APP_CHANNEL_OPTION = "SHOW_APP_CHANNEL_OPTION",
}

export default class FeatureToggleStore {
  apiService: ApiService
  orgUnitStore: OrgUnitStore
  userNotificationStore: UserNotificationStore

  private featureOverrides = new Map<string, boolean>()

  private features?: { [key: string]: boolean }
  featuresLoaded = false

  get SHOW_TOGGLE_TO_ENABLE_PRINTING_ENABLED() {
    return this.isFeatureToggleEnabled(FeatureToggle.SHOW_TOGGLE_TO_ENABLE_PRINTING)
  }

  get shouldShowAppChannelOption() {
    return this.isFeatureToggleEnabled(FeatureToggle.SHOW_APP_CHANNEL_OPTION)
  }

  private isFeatureToggleKeyEnabled(key: string) {
    return this.orgUnitStore.selectedOrgUnit?.features?.[key] ?? this.features?.[key] ?? false
  }

  public isFeatureToggleEnabled(key: FeatureToggle) {
    if (this.featureOverrides.get(key) !== undefined) return this.featureOverrides.get(key) === true

    return (
      this.isFeatureToggleKeyEnabled(key) ||
      (this.isFeatureToggleKeyEnabled(key + "_PER_ORG_MODE") && this.isFeatureToggleKeyEnabled(key + "_PER_ORG"))
    )
  }

  private updateLocalStorageForKey(key?: string | null) {
    if (key?.startsWith(FEATURE_TOGGLE_PREFIX)) {
      this.featureOverrides.set(key.substring(FEATURE_TOGGLE_PREFIX.length), localStorage.getItem(key) === "true")
    }
  }

  constructor(orgUnitStore: OrgUnitStore, apiService: ApiService, userNotificationStore: UserNotificationStore) {
    this.orgUnitStore = orgUnitStore
    this.apiService = apiService
    this.userNotificationStore = userNotificationStore

    makeAutoObservable(this)
    for (let i = 0; i < localStorage.length; i++) {
      this.updateLocalStorageForKey(localStorage.key(i))
    }

    when(
      () => this.apiService.authStore.isLoggedIn,
      async () => {
        await setIntervalDelay(async () => {
          await this.fetchFeatures()
        }, 120_000)
      }
    )
  }

  async fetchFeatures() {
    try {
      const response = await this.apiService.get("v1/features")
      if (response.ok) {
        // Another possibility would be to create a reaction to watch features
        const features = await response.json()
        runInAction(() => {
          this.features = features
          this.featuresLoaded = true
        })
      }
      this.userNotificationStore.resolveApiResponseError("FeatureToggleStore")
    } catch (response: any) {
      console.log("FeatureToggleStore failed to load features", response)
      this.userNotificationStore.handleApiResponseDefaultErrors(
        "FeatureToggleStore",
        response,
        "Laden der Featurekonfiguration fehlgeschlagen",
        "blocking",
        {
          callback: () => this.fetchFeatures(),
        }
      )

      return response.ok
    }
  }
}
