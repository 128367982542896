import dayjs from "dayjs"
import { observer } from "mobx-react-lite"
import React, { useContext, useState } from "react"
import { Button, Card, CardBody, Collapse, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { ServiceManagerContext } from "../../../../AppServiceManager"
import "./UserInfoModal.scss"
import ClipboardJS from "clipboard"

class ClipboardButton extends React.Component<{ content: string }> {
  componentDidMount() {
    new ClipboardJS(".clipboard-btn")
  }

  render() {
    return (
      <Button color="primary" className="clipboard-btn" data-clipboard-text={this.props.content}>
        In die Zwischenablage kopieren
      </Button>
    )
  }
}

const UserInfoModal = () => {
  const [detailsVisible, setDetailsVisible] = useState(false)

  const context = useContext(ServiceManagerContext)

  const onClose = () => {
    if (detailsVisible) {
      toggleDetails()
    }
    context?.userNotificationStore.closeInfoModal()
  }

  const retry = () => {
    context?.userNotificationStore.infoModal?.modalAction?.callback?.()
    onClose()
  }

  const toggleDetails = () => {
    setDetailsVisible((detailsVisible) => !detailsVisible)
  }

  const userNotificationState = context?.userNotificationStore.infoModal

  if (!userNotificationState) return null

  const modalTitle = userNotificationState.modalTitle
  const modalMessage = userNotificationState.modalMessage
  const modalData = userNotificationState.modalData
  const clipboardContent = `=== ZSB-Cockpit Hinweismeldung ('${dayjs().format()}') ===\n${modalTitle}\n${modalMessage}\n${modalData}`

  return (
    <div>
      <Modal isOpen={true} className="ErrorModal">
        <ModalHeader toggle={onClose}>{userNotificationState.modalTitle}</ModalHeader>

        <ModalBody>
          {userNotificationState.modalMessage}
          <Collapse isOpen={detailsVisible}>
            <Card>
              <CardBody>
                <pre>{modalData}</pre>
              </CardBody>
              <ClipboardButton content={clipboardContent} />
            </Card>
          </Collapse>
        </ModalBody>

        <ModalFooter>
          {!!userNotificationState.modalData && (
            <Button color="link" onClick={toggleDetails}>
              {detailsVisible ? "Details verbergen" : "Details"}
            </Button>
          )}
          {!!userNotificationState.modalAction && (
            <Button color="primary" onClick={retry}>
              {userNotificationState.modalAction.name}
            </Button>
          )}
          <Button color="secondary" onClick={onClose}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default observer(UserInfoModal)
