import React from "react"
import SortCaret, { SortDirection } from "./SortCaret"

interface TableSortHeaderProps {
  headerText: string
  sortDirection: SortDirection
  showCaret: boolean
  rest?: unknown
}

const TableSortHeader = ({ headerText, sortDirection, showCaret, ...rest }: TableSortHeaderProps) => (
  <span style={{ position: "relative", display: "inline-block" }} {...rest}>
    {headerText}
    <SortCaret direction={sortDirection} showCaret={showCaret} />
  </span>
)

export default TableSortHeader
