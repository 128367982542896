import { observer } from "mobx-react-lite"
import React, { useContext, useState, useRef } from "react"
import { ServiceManagerContext } from "../../../../AppServiceManager"
import SelectOrgUnit from "../SharedComponents/SelectOrgUnit/SelectOrgUnit"
import "./TopNavigation.scss"
import SearchInput from "../SharedComponents/SearchInput/SearchInput"
import { useOnClickOutside } from "../../../utils/useOnClickOutside"
import QuickSearch from "./QuickSearch"
import { Button } from "reactstrap"
import SettingsModal from "../SettingsModal/SettingsModal"

const TopNavigation = () => {
  const { orgUnitStore, zsbSorterConfigStore } = useContext(ServiceManagerContext)
  const { searchString, setSearchString } = zsbSorterConfigStore
  const [showQuickSearch, setShowQuickSearch] = useState(false)
  const [showSettingsModal, setShowSettingsModal] = useState(false)

  const toggleSettingsModal = () => setShowSettingsModal((showSettingsModal) => !showSettingsModal)

  const ref = useRef(null)
  useOnClickOutside(ref, () => setShowQuickSearch(false))

  if (!orgUnitStore) return null
  return (
    <div className="TopNavigation" data-userguide-id="top-bar-controllers">
      <SelectOrgUnit />
      <div className="d-flex justify-space-between">
        <div className="AdvancedSearch position-relative" ref={ref}>
          <SearchInput
            value={searchString}
            bsSize="md"
            placeholder="Suche nach Scannern..."
            onChange={setSearchString}
            onFocus={() => setShowQuickSearch(true)}
          />
          {showQuickSearch && <QuickSearch setSearchString={setSearchString} setShowQuickSearch={setShowQuickSearch} />}
        </div>
        <Button className="border-0 ms-2" color="secondary" outline size="sm" onClick={toggleSettingsModal}>
          Standardeinstellungen bearbeiten
        </Button>
      </div>
      {showSettingsModal && <SettingsModal toggle={toggleSettingsModal} />}
    </div>
  )
}

export default observer(TopNavigation)
