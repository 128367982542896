import getConfig from "./../../../../env"
import DeviceManagementLogo from "./../../../assets/device-management-logo.svg"
import LMAUbersichtIcon from "./../../../assets/lma-ubersicht-icon.svg"
import LastMileAdminLogo from "./../../../assets/last-mile-adm-logo.svg"
import ZSBCockpitLogo from "./../../../assets/zsb-cockpit-logo.svg"

type LinkedApp = {
  label: string
  href: string
  icon?: string
}

export function getLinkedApps(selectedOrgIdPath: string): LinkedApp[] {
  return [
    {
      label: "ZSB-Cockpit",
      href: `${getConfig().linkedAppsURLs.zsbCockpit}${selectedOrgIdPath}`,
      icon: ZSBCockpitLogo,
    },
    {
      label: "Last Mile Analytics",
      href: `${getConfig().linkedAppsURLs.lastMileAnalytics}`,
      icon: LMAUbersichtIcon,
    },
    {
      label: "Last Mile Administration",
      href: `${getConfig().linkedAppsURLs.lastMileAdm}${selectedOrgIdPath}`,
      icon: LastMileAdminLogo,
    },
    {
      label: "Device Management",
      href: `${getConfig().linkedAppsURLs.deviceManagment}${selectedOrgIdPath}`,
      icon: DeviceManagementLogo,
    },
  ]
}
