import { makeAutoObservable } from "mobx"
import React from "react"
import { v1 as uuidV1 } from "uuid"
import { AppServiceManager } from "../../../../AppServiceManager"
import { FetchRejectionResult } from "../../api/ApiService"

export type MessageColor = "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" | null

export interface ToastMessage {
  id: string
  uniqueKey?: string | null
  title: string
  message?: React.ReactNode | null
  color?: MessageColor
}

export interface ModalAction {
  name: string
  callback: () => void
}

interface InfoModal {
  modalTitle: string
  modalMessage: React.ReactNode
  modalData?: string
  modalAction?: ModalAction
}

export interface BroadcastNotification {
  messageType: "none" | "warning" | "error" | "info" | undefined
  title: string | undefined
  text: string | undefined
  visibleFrom: number // date in Seconds
  visibleUntil: number // date in Seconds
}

const restartAction = {
  name: "App Neustarten",
  callback: () => {
    return window.location.reload()
  },
}

export default class UserNotificationStore {
  private appServiceManager?: AppServiceManager = undefined

  toastMessages: ToastMessage[] = []
  infoModal?: InfoModal = undefined
  private lastFetchRejection?: number = undefined
  fetchRejectionSince?: number = undefined

  private lastFetchedBroadcastNotification?: string

  constructor(appServiceManager?: AppServiceManager) {
    this.appServiceManager = appServiceManager
    makeAutoObservable(this)
  }

  showInfoModal(title: string, message: React.ReactNode, data?: unknown, action?: ModalAction): void {
    this.infoModal = {
      modalTitle: title,
      modalMessage: message,
      modalData: typeof data === "string" ? data : JSON.stringify(data, undefined, 2),
      modalAction: action,
    }
  }

  closeInfoModal(): void {
    this.infoModal = undefined
  }

  addToastMessage(
    title: string,
    message: React.ReactNode | undefined,
    uniqueKey?: string,
    color?: MessageColor,
    hideTimeoutSeconds: number | null = 10
  ) {
    const id = uuidV1()
    if (uniqueKey && this.toastMessages.some((tm) => tm.uniqueKey === uniqueKey)) {
      return
    }
    this.toastMessages.push({
      id,
      uniqueKey,
      title,
      message,
      color,
    })
    if (hideTimeoutSeconds) {
      setTimeout(() => this.removeToastMessage(id), hideTimeoutSeconds * 1000)
    }
  }

  addSuccessToastMessage(title: string, message?: string | undefined, uniqueKey?: string, showSeconds = 2.5) {
    this.addToastMessage(title, message, uniqueKey, "success", showSeconds)
  }

  removeToastMessage(id: string) {
    this.toastMessages = this.toastMessages.filter((m: ToastMessage) => m.id !== id)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async resolveApiResponseError(_module: string) {
    this.lastFetchRejection = undefined
    this.fetchRejectionSince = undefined
  }

  async handleApiResponseDefaultErrors(
    module: string,
    responseOrError: Response | Error | FetchRejectionResult,
    title: string,
    displayPriority: "blocking" | "info" | "log_only",
    options?: {
      module?: string
      callback?: () => void
    }
  ) {
    const response = responseOrError as Response
    if (!response.status) {
      const message = "Die Verbindung zum Server ist fehlgeschlagen. Bitte überprüfe Deine Internetverbindung."
      switch (displayPriority) {
        case "blocking":
          // this.showInfoModal(title, message, null, action)
          if (!this.lastFetchRejection) this.lastFetchRejection = Date.now()
          this.fetchRejectionSince = Date.now() - this.lastFetchRejection
          break
        case "info":
          this.addToastMessage(title, message, "fetchRejection")
          break
        default:
          this.showInfoModal(title, message, "fetchRejection")
          break
      }
    } else {
      const action = options?.callback
        ? {
            callback: options.callback,
            name: "Nochmal Versuchen",
          }
        : undefined

      const { ok, redirected, status, statusText, type, url } = response
      let details: Record<string, unknown> = {}
      if (response.text) {
        details = {
          ok,
          redirected,
          status,
          statusText,
          type,
          url,
        }
        try {
          details.body = await response.text()
        } catch (error: any) {
          details.errorMessage = error.message
          details.stack = error.stack
        }
      }

      if (status === 401) {
        console.warn(`${module} unauthorizedError`, title)
      } else if (status === 404) {
        const message = "Das Element existiert nicht."
        this.showInfoModal(title, message, details)
      } else if (status >= 400 && status < 500) {
        const message =
          "Die übertragenen Daten wurden vom Server nicht akzeptiert. Bitte kontaktiere Deinen Ansprechpartner."
        this.showInfoModal(title, message, details, restartAction)
      } else if (status >= 500 && status < 600) {
        const message =
          "Es ist ein Fehler bei der Datenverarbeitung aufgetreten. Falls der Fehler länger besteht kontaktiere bitte Deinen Ansprechpartner."
        this.showInfoModal(title, message, details, action)
      } else {
        const message = "Es ist ein unbekannter Fehler aufgetreten. Bitte kontaktiere Deinen Ansprechpartner."
        this.showInfoModal(title, message, details, restartAction)
      }
    }
  }

  hasRelevantBroadcastMessage = (notification: BroadcastNotification): boolean => {
    return (
      !!notification.messageType && notification.messageType !== "none" && !!notification.title && !!notification.text
    )
  }

  isNewBroadcastMessage = (notification: BroadcastNotification): boolean => {
    if (!notification.title || !notification.text) return false
    return this.lastFetchedBroadcastNotification != this.getMessageHash(notification)
  }

  getMessageHash = (notification: BroadcastNotification): string => {
    return (notification.title ?? "") + (notification.text ?? "")
  }

  getBroadcastMessageColor = (notification: BroadcastNotification): MessageColor => {
    switch (notification.messageType) {
      case "warning":
        return "warning"
      case "error":
        return "danger"
      case "info":
        return "primary"
    }
    return null
  }
}
