import React, { CSSProperties } from "react"
import { Button, Input } from "reactstrap"
import "./SearchInput.scss"
import classNames from "classnames"
import { observer } from "mobx-react-lite"

type SearchInputProps = {
  className?: string
  value?: string
  onChange: (nextValue: string) => void
  onClick?: (event: React.MouseEvent) => void
  style?: CSSProperties
  bsSize?: "lg" | "md" | "sm"
  additionalActions?: React.ReactNode | React.ReactNodeArray
  dataTestId?: string
  placeholder?: string
  onFocus?: () => void
}

const SearchInput: React.FC<SearchInputProps> = ({
  className,
  value,
  onChange,
  onClick,
  style,
  bsSize,
  dataTestId,
  additionalActions,
  placeholder,
  onFocus,
}: SearchInputProps) => {
  return (
    <div className={classNames("SearchInput", className)} style={style}>
      <Input
        value={value}
        onChange={(event) => onChange(event.target.value)}
        onClick={onClick}
        type="text"
        bsSize={bsSize === "md" ? undefined : bsSize ?? "sm"}
        placeholder={placeholder ?? "Suchen..."}
        style={{ marginRight: 10 }}
        data-testid={dataTestId}
        onFocus={onFocus}
      />
      <div className="search-input-additional">
        {value && (
          <Button onClick={() => onChange("")} color="link" className="btn-sm">
            <i className="fas fa-times" />
          </Button>
        )}
        {additionalActions}
      </div>
    </div>
  )
}

export default observer(SearchInput)
