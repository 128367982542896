import React from "react"
import "./ConfigSymbolContainer.scss"
import classnames from "classnames"

type ConfigSymbolContainerProps = {
  isDefault?: boolean
  children: React.ReactNode
}

const ConfigSymbolContainer = ({ isDefault, children }: ConfigSymbolContainerProps) => (
  <div className={classnames("ConfigSymbolContainer", { isDefault: isDefault })}>{children}</div>
)

export default ConfigSymbolContainer
