import { observer } from "mobx-react-lite"
import React, { useContext } from "react"
import { ServiceManagerContext } from "../../../../AppServiceManager"
import { ZsbSorterConfig } from "../../model/store/ZsbSorterConfigStore"
import RadioGroupSetting from "./RadioGroupSetting"
import SettingContainer from "./SettingContainer"
import SortForDateSetting from "./SortForDateSetting"
import ToggleSetting from "./ToggleSetting"

type ZsbSorterConfigsProps = {
  defaultConfig?: boolean
}

const ZsbSorterConfigs = ({ defaultConfig = false }: ZsbSorterConfigsProps) => {
  const { environmentConfig, featureToggleStore, zsbSorterConfigStore } = useContext(ServiceManagerContext)

  let configurations: ZsbSorterConfig[]
  if (defaultConfig) {
    if (!zsbSorterConfigStore.defaultConfig) return <></>
    configurations = [zsbSorterConfigStore.defaultConfig]
  } else {
    configurations = zsbSorterConfigStore.selectedConfigurations
  }

  const updateConfigurations = defaultConfig
    ? zsbSorterConfigStore.updateDefaultConfiguration
    : zsbSorterConfigStore.updateConfigurations

  const validStageOptions = {
    ...zsbSorterConfigStore.appChannelOptions,
    options:
      environmentConfig.appChannel != "production"
        ? zsbSorterConfigStore.appChannelOptions.options
        : zsbSorterConfigStore.appChannelOptions.options.filter(({ key }) => key === "production" || key === "canary"),
  }

  return (
    <>
      <SettingContainer header="Sortieren für Datum">
        <SortForDateSetting configurations={configurations} updateConfigurations={updateConfigurations} />
      </SettingContainer>
      <SettingContainer header="Sortiermodus">
        {featureToggleStore.SHOW_TOGGLE_TO_ENABLE_PRINTING_ENABLED && !defaultConfig && (
          <>
            <ToggleSetting
              labelOn={
                <span>
                  Sortiermodus <i>Labeldruck</i> kann verwendet werden
                </span>
              }
              labelOff={
                <span>
                  Sortiermodus <i>Labeldruck</i> kann nicht verwendet werden
                </span>
              }
              settingKey={"LABEL_PRINT_ENABLED"}
              configurations={configurations}
              updateConfigurations={updateConfigurations}
            />
          </>
        )}
        <RadioGroupSetting
          groupSetting={
            defaultConfig ? zsbSorterConfigStore.defaultScanModeOptions() : zsbSorterConfigStore.scanModeOptions
          }
          configurations={configurations}
          updateConfigurations={updateConfigurations}
        />
      </SettingContainer>
      <SettingContainer header="Sortierziel scannen">
        <RadioGroupSetting
          groupSetting={zsbSorterConfigStore.enforceVerifyOptions}
          configurations={configurations}
          updateConfigurations={updateConfigurations}
        />
      </SettingContainer>
      {featureToggleStore.shouldShowAppChannelOption && (
        <SettingContainer header="Umgebung">
          <RadioGroupSetting
            groupSetting={validStageOptions}
            configurations={configurations}
            updateConfigurations={updateConfigurations}
          />
        </SettingContainer>
      )}
    </>
  )
}

export default observer(ZsbSorterConfigs)
