import React from "react"
import { ScanCorrectIcon, ScanDisabledIcon, ScanIcon } from "../SharedComponents/Icons/Icons"
import ConfigSymbolContainer from "./ConfigSymbolContainer"
import { SettingProps } from "./SettingProps"

const EnforceVerifyIcon: React.FC<SettingProps> = ({ setting, defaultConfig }: SettingProps) => {
  const isDefault = !setting?.value
  const currentSetting = setting?.value ?? defaultConfig?.value

  return (
    <ConfigSymbolContainer isDefault={isDefault}>
      {currentSetting === "DISABLED" && <ScanDisabledIcon />}
      {currentSetting === "ENFORCE_SCAN" && <ScanIcon />}
      {currentSetting === "ENFORCE_CORRECT_SCAN" && <ScanCorrectIcon />}
    </ConfigSymbolContainer>
  )
}

export default EnforceVerifyIcon
