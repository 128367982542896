import { observer } from "mobx-react-lite"
import React, { useContext } from "react"
import { ServiceManagerContext } from "../../../../AppServiceManager"
import { RadioBoxGroup } from "../../components/SharedComponents"
import { ZsbSorterConfig, ZsbSorterSettingKey } from "../../model/store/ZsbSorterConfigStore"
import fastCopy from "fast-copy"

export interface RadioBoxOption {
  key?: string
  label: string
}

export interface RadioGroupSettingInterface {
  key: ZsbSorterSettingKey
  options: RadioBoxOption[]
  default: string
}

interface RadioGroupSettingProps {
  groupSetting: RadioGroupSettingInterface
  configurations: ZsbSorterConfig[]
  updateConfigurations: (configs: ZsbSorterConfig[]) => Promise<any>
}

const RadioGroupSetting: React.FC<RadioGroupSettingProps> = ({
  groupSetting,
  configurations,
  updateConfigurations,
}: RadioGroupSettingProps) => {
  const { zsbSorterConfigStore, orgUnitStore } = useContext(ServiceManagerContext)

  const onChange = (settingsKey: ZsbSorterSettingKey, value: any) => {
    const next = fastCopy(configurations)
    next.forEach((sorterConfig) => {
      sorterConfig.settings[settingsKey] = {
        key: settingsKey,
        value,
        selfControlled: value === "SELF_CONTROLLED",
      }
    })
    updateConfigurations(next)
  }

  const getCurrentValue = (settingsKey: string): string | undefined => {
    const values = new Set<any | undefined>(configurations.map((it) => it.settings[settingsKey]?.value))
    if (values.size === 1)
      return (
        values.values().next().value ??
        zsbSorterConfigStore.defaultConfig?.settings[settingsKey]?.value ??
        "SELF_CONTROLLED"
      )
  }

  const currentValue = getCurrentValue(groupSetting.key)

  return (
    <RadioBoxGroup
      disabled={!orgUnitStore.hasWritePermission || zsbSorterConfigStore.isUpdateRequestPending}
      options={groupSetting.options}
      selected={currentValue}
      onChange={(nextKey) => onChange(groupSetting.key, nextKey)}
    />
  )
}

export default observer(RadioGroupSetting)
