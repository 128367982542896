export const AppColors = {
  PRIMARY: "primary",
  ACCENT: "accent",
  SIDEBAR_PROD: "sidebar-prod",
  SIDEBAR_STAGING: "sidebar-staging",
  SIDEBAR_LOCAL: "sidebar-local",
}

export default class CssUtils {
  static getVariable(name: string) {
    name = name.startsWith("--") ? name : "--" + name
    return getComputedStyle(document.documentElement).getPropertyValue(name)
  }
}
