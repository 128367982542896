import { orderBy } from "lodash"
import { observer } from "mobx-react-lite"
import React, { useContext, useMemo, useState } from "react"
import { Input } from "reactstrap"
import { ServiceManagerContext } from "../../../../AppServiceManager"
import { Table } from "../../components/SharedComponents"
import { SortDirection } from "../../components/SharedComponents/Table/SortCaret"
import TableSortHeader from "../../components/SharedComponents/Table/TableSortHeader"
import { ZsbSorterConfig } from "../../model/store/ZsbSorterConfigStore"
import { SortCriteria } from "./Home"
import {
  ZsbSorterConfigRow,
  ZsbSorterConfigRowEmpty,
  ZsbSorterConfigRowNothingFound,
  ZsbSorterConfigRowSkeleton,
} from "./ZsbSorterConfigRow"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"

dayjs.extend(customParseFormat)

const ZsbSorterTable = () => {
  const { zsbSorterConfigStore } = useContext(ServiceManagerContext)
  const sortCriterias: SortCriteria[] = [
    { value: "label", defaultOrder: "asc" },
    { value: "serialNo", defaultOrder: "asc" },
    { value: "deviceType", defaultOrder: "asc" },
  ]
  const [sortCriteria, setSortCriteria] = useState<SortCriteria>(sortCriterias[0])
  const [sortDirection, setSortDirection] = useState<SortDirection>("asc")
  const {
    configurations,
    configurationsLoading,
    selectedConfigurationIds,
    selectConfigurations,
    changeSelection,
    searchString,
    openDetails,
    getBySortForDate,
    getBySortMode,
    scanModeOptions,
  } = zsbSorterConfigStore
  const lowerCaseSearchString = searchString.toLowerCase()

  const filtered = useMemo(() => {
    if (searchString === "") return configurations
    if (searchString.startsWith("Sortiermodus=")) {
      const sortMode = scanModeOptions.options.find((it) => it.label === searchString.replace("Sortiermodus=", ""))?.key
      return getBySortMode(sortMode)
    }
    if (searchString.startsWith("Sortierdatum=")) {
      const sortForToday = searchString.replace("Sortierdatum=", "").toLocaleLowerCase().indexOf("heute") !== -1
      return getBySortForDate(sortForToday ? "CURRENT" : "NEXT")
    }

    return configurations.filter(
      (it) =>
        it.serialNo.toLowerCase().includes(lowerCaseSearchString) ||
        it.label.toLowerCase().includes(lowerCaseSearchString) ||
        it.deviceType.toLowerCase().includes(lowerCaseSearchString)
    )
  }, [configurations, lowerCaseSearchString])

  const orderedConfigurations = useMemo(
    () =>
      orderBy(
        filtered,
        (config: ZsbSorterConfig) => {
          switch (sortCriteria.value) {
            case "label":
              return config.label
            case "serialNo":
              return config.serialNo
            case "deviceType":
              return config.deviceType
            default:
          }
        },
        sortDirection
      ),
    [filtered, sortCriteria, sortDirection]
  )

  const allSelected = configurations.length === selectedConfigurationIds.size

  const onSortOrderToggleClick = () => {
    const newSortDirection = sortDirection === "asc" ? "desc" : "asc"
    setSortDirection(newSortDirection)
  }

  const onSortHeaderClick = (criteria: SortCriteria) => {
    if (sortCriteria.value == criteria.value) {
      onSortOrderToggleClick()
    } else {
      setSortDirection(criteria.defaultOrder)
      setSortCriteria(criteria)
    }
  }
  const toggleSelectAll = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (allSelected) selectConfigurations([])
    else selectConfigurations(filtered)
  }

  const openDetailsSingle = (config: ZsbSorterConfig) => {
    selectConfigurations([config])
    openDetails()
  }

  return (
    <div className="ZsbSorterTable">
      <Table>
        <thead>
          <tr>
            <th className="checkbox-cell" style={{ width: 30 }} onClick={toggleSelectAll}>
              <Input type="checkbox" checked={allSelected} readOnly />
            </th>
            <th className="cursor-pointer" onClick={() => onSortHeaderClick(sortCriterias[0])}>
              <TableSortHeader
                headerText="Beschreibung"
                sortDirection={sortDirection}
                showCaret={sortCriteria.value === "label"}
              />
            </th>
            <th className="cursor-pointer d-none d-lg-table-cell" onClick={() => onSortHeaderClick(sortCriterias[1])}>
              <TableSortHeader
                headerText="Seriennummer"
                sortDirection={sortDirection}
                showCaret={sortCriteria.value === "serialNo"}
              />
            </th>
            <th className="cursor-pointer d-none d-lg-table-cell" onClick={() => onSortHeaderClick(sortCriterias[2])}>
              <TableSortHeader
                headerText="Scanner-Typ"
                sortDirection={sortDirection}
                showCaret={sortCriteria.value === "deviceType"}
              />
            </th>
            <th style={{ width: 160 }}>Einstellungen</th>
          </tr>
        </thead>
        <tbody>
          {filtered.length === 0 && configurationsLoading && <ZsbSorterConfigRowSkeleton />}
          {filtered.length === 0 && !configurationsLoading && !!searchString && <ZsbSorterConfigRowNothingFound />}
          {filtered.length === 0 && !configurationsLoading && !searchString && <ZsbSorterConfigRowEmpty />}
          {orderedConfigurations.map((config) => (
            <ZsbSorterConfigRow
              key={config.serialNo}
              config={config}
              isSelected={selectedConfigurationIds.has(config.serialNo)}
              onClick={openDetailsSingle}
              changeSelection={changeSelection}
              search={searchString}
            />
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end m-3 font-weight-bold">
        {zsbSorterConfigStore.selectedConfigurations.length
          ? `${zsbSorterConfigStore.selectedConfigurations.length} von ${orderedConfigurations.length} Geräten ausgewählt`
          : `${orderedConfigurations.length} Geräte`}
      </div>
    </div>
  )
}

export default observer(ZsbSorterTable)
