import React, { useContext } from "react"
import { orderBy } from "lodash"
import "./ZsbSorterConfigDetails.scss"
import { Button } from "reactstrap"
import { ServiceManagerContext } from "../../../../AppServiceManager"
import { observer } from "mobx-react-lite"
import { useWindowSize } from "../../../utils/useWindowSize"
import ZsbSorterConfigs from "./ZsbSorterConfigs"

const ZsbSorterConfigDetails: React.FC = () => {
  const { zsbSorterConfigStore, orgUnitStore } = useContext(ServiceManagerContext)
  const { width } = useWindowSize()
  const {
    showDetails,
    selectedConfigurations,
    doAllSelectedHaveDefaultConfigsOnly,
    hideDetails,
    deleteConfigurations,
  } = zsbSorterConfigStore
  const show = showDetails || width >= 576

  if (!show) return null

  if (show && !selectedConfigurations.length)
    return <div className="ZsbSorterConfigDetails no-config">Bitte wähle einen Scanner auf der linken Seite</div>

  const title = orderBy(
    // if device has no label, take serialNo for config header
    selectedConfigurations.map((it) => (it.label ? it.label : it.serialNo)),
    (it) => it
  ).join(", ")

  const resetToDefault = () => deleteConfigurations(selectedConfigurations)

  return (
    <div className="ZsbSorterConfigDetails">
      <div className="header">
        <div className="details-title font-weight-bold nowrap-ellipsis">{title}</div>
        <Button className="closeButton" close onClick={hideDetails} />
      </div>
      <div className="details-body">
        <ZsbSorterConfigs />
        {doAllSelectedHaveDefaultConfigsOnly ? (
          <div className="font-weight-bold">Alle ausgewählten Geräte nutzen die Standardeinstellungen.</div>
        ) : (
          <Button onClick={resetToDefault} color="secondary" disabled={!orgUnitStore.hasWritePermission}>
            Auf Standardeinstellungen zurücksetzen
          </Button>
        )}
      </div>
    </div>
  )
}

export default observer(ZsbSorterConfigDetails)
