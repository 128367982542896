import React from "react"
import RadioBox from "./RadioBox"
import { RadioBoxOption } from "../../../sites/home/RadioGroupSetting"

interface RadioBoxGroupProps {
  options: RadioBoxOption[]
  selected?: string
  onChange: (nextKey: string) => void
  disabled?: boolean
  footerComponent?: React.ReactNode
}

const RadioBoxGroup: React.FC<RadioBoxGroupProps> = ({
  options,
  selected,
  onChange,
  disabled,
  footerComponent,
}: RadioBoxGroupProps) => {
  return (
    <div className="RadioBoxGroup">
      {options.map((option, index) => {
        // checked if key is set and selected option eq key OR if no key is set and no other option is selected
        // this allows to use a custom input value as key
        const checked =
          (!!option.key && option.key === selected) ||
          (!option.key && !!selected && !options.find((o) => o.key === selected))

        return (
          <RadioBox
            key={option.key ?? index.toString()}
            checked={checked}
            label={option.label}
            onChange={() => onChange(option.key ?? index.toString())}
            disabled={disabled}
          />
        )
      })}
      {!!footerComponent && <div className="m-1 ms-4">{footerComponent}</div>}
    </div>
  )
}

export default RadioBoxGroup
