import React, { useContext, useEffect } from "react"
import { RouteComponentProps } from "@reach/router"
import { ServiceManagerContext } from "../../../../AppServiceManager"
import { SortDirection } from "../../components/SharedComponents/Table/SortCaret"
import "./Home.scss"
import ZsbSorterConfigDetails from "./ZsbSorterConfigDetails"
import { observer } from "mobx-react-lite"
import { Button } from "reactstrap"
import ZsbSorterTable from "./ZsbSorterTable"

export type SortCriteria = {
  value: "label" | "serialNo" | "deviceType"
  defaultOrder: SortDirection
}

const Home = ({ orgId }: { orgId?: string } & RouteComponentProps) => {
  const { orgUnitStore, zsbSorterConfigStore } = useContext(ServiceManagerContext)
  const { selectedConfigurationIds, openDetails } = zsbSorterConfigStore

  useEffect(() => {
    // TODO move this to Navigation?
    if (orgId) orgUnitStore.onSelectOrgUnit(orgId)
  }, [orgId])

  return (
    <div className="Home">
      <ZsbSorterTable />
      {!!selectedConfigurationIds.size && (
        <div className="OpenDetailsButton p-3">
          <Button onClick={openDetails} color="primary">
            Einstellungen öffnen
          </Button>
        </div>
      )}

      <ZsbSorterConfigDetails />
    </div>
  )
}

export default observer(Home)
