import { StylesConfig } from "react-select"
import CssUtils, { AppColors } from "../../../../utils/CssUtils"

export const INPUT_HEIGHT = "calc(1.5em + 0.75rem)"
export const INPUT_HEIGHT_SMALL = 27

export const baseStyles = <OptionType = null, IsMulti extends boolean = false>(
  styles: StylesConfig<OptionType, IsMulti> = {},
  invalid?: boolean,
  small?: boolean
): StylesConfig<OptionType, IsMulti> => {
  const {
    container,
    option,
    control,
    valueContainer,
    menu,
    menuPortal,
    dropdownIndicator,
    clearIndicator,
    ...otherStyles
  } = styles

  const invalidStyles = invalid
    ? {
        borderColor: "#dc3545",
      }
    : {}

  return {
    container: (base, props) => ({
      ...base,
      height: small ? INPUT_HEIGHT_SMALL : INPUT_HEIGHT,
      minHeight: small ? INPUT_HEIGHT_SMALL : INPUT_HEIGHT,
      ...(container ? container(base, props) : {}),
    }),
    option: (base, { isFocused, ...props }) => ({
      ...base,
      backgroundColor: isFocused ? "#eee" : "white",
      color: "black",
      cursor: "pointer",
      [":active"]: {
        backgroundColor: CssUtils.getVariable(AppColors.PRIMARY),
      },
      ...(option ? option(base, { isFocused, ...props }) : {}),
    }),
    control: (base, props) => ({
      ...base,
      cursor: "pointer",
      height: small ? INPUT_HEIGHT_SMALL : INPUT_HEIGHT,
      minHeight: small ? INPUT_HEIGHT_SMALL : INPUT_HEIGHT,
      ...(control ? control(base, props) : {}),
      ...invalidStyles,
    }),
    valueContainer: (base, props) => ({
      ...base,
      cursor: "text",
      height: small ? INPUT_HEIGHT_SMALL - 2 : INPUT_HEIGHT,
      minHeight: small ? INPUT_HEIGHT_SMALL - 2 : INPUT_HEIGHT,
      fontSize: small ? "0.875rem" : base.fontSize,
      padding: small ? "0 8px" : base.padding,
      ...(valueContainer ? valueContainer(base, props) : {}),
    }),
    menu: (base, props) => ({
      ...base,
      zIndex: 10,
      ...(menu ? menu(base, props) : {}),
    }),
    menuPortal: (base, props) => ({
      ...base,
      zIndex: 9999,
      ...(menuPortal ? menuPortal(base, props) : {}),
    }),
    dropdownIndicator: (base, props) => ({
      ...base,
      padding: small ? 0 : "0.25rem 0.5rem",
      width: small ? INPUT_HEIGHT_SMALL : INPUT_HEIGHT,
      display: "flex",
      justifyContent: "center",
      ...(dropdownIndicator ? dropdownIndicator(base, props) : {}),
    }),
    clearIndicator: (base, props) => ({
      ...base,
      cursor: "pointer",
      padding: small ? 0 : "0.25rem 0.5rem",
      width: small ? INPUT_HEIGHT_SMALL : INPUT_HEIGHT,
      display: "flex",
      justifyContent: "center",
      ...(clearIndicator ? clearIndicator(base, props) : {}),
    }),
    ...otherStyles,
  }
}
