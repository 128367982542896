import React from "react"
import { Button } from "reactstrap"

interface Props {
  changeSearchInput: () => void
  amount: number
  color?: string
  label: string
}

const QuickSearchButton = ({ changeSearchInput, amount, color = "#7F7F7F", label }: Props) => (
  <Button
    className="QuickSearchButton me-2 flex-1 position-relative d-inline-flex flex-column align-items-center"
    color=""
    style={{ color: color, border: `1px solid ${color}` }}
    onClick={changeSearchInput}
  >
    <span style={{ fontSize: 28 }}>{amount}</span>
    <span>{label}</span>
  </Button>
)

export default QuickSearchButton
