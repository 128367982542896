import timeoutPromise from "../../utils/timeoutPromise"
import AuthStore from "../model/store/AuthStore"
import { HttpHeaders } from "./HttpHeaders"
import HttpRequestTypes, { HttpRequestType } from "./HttpRequestType"
import { RequestStatistics } from "./RequestStatistics"
import RequestUtil from "./RequestUtil"

export type FetchRejectionResult = {
  ok: boolean
  fetchRejection: boolean
  error: Error
}

interface RequestOptions {
  headers?: {
    [key: string]: string | undefined
  }
  timeout?: number
  additionalRequestConfig?: Partial<Request>
  preventMinivan?: boolean
  abortController?: AbortController
}

export default class ApiService {
  authStore: AuthStore

  apiBase: string

  constructor(authStore: AuthStore, apiBase: string) {
    this.authStore = authStore
    this.apiBase = apiBase
  }

  get = async (path: string, options?: RequestOptions): Promise<Response> =>
    this.request(HttpRequestTypes.GET, path, undefined, options)

  post = async (path: string, body: unknown, options?: RequestOptions): Promise<Response> =>
    this.request(HttpRequestTypes.POST, path, body, options)

  delete = async (path: string, body: unknown, options?: RequestOptions): Promise<Response> =>
    this.request(HttpRequestTypes.DELETE, path, body, options)

  async request(method: HttpRequestType, path: string, body?: unknown, options?: RequestOptions): Promise<Response> {
    const headers = options?.headers
    const timeout = options?.timeout ?? 10000
    const abortController = options?.abortController
    const additionalRequestConfig = options?.additionalRequestConfig
    const preventMinivan = !!options?.preventMinivan

    await this.authStore.onStartedRequest(preventMinivan)

    if (!this.authStore.authToken) {
      throw Error("Nicht angemeldet")
    }
    let url = this._getApiBase() + path
    if (path.match(/(http:\/\/.+)|(https:\/\/)/)) {
      url = path
    }
    const requestStats: RequestStatistics = {
      success: false,
      firedAt: Date.now(),
      executionCount: 1,
    }

    const customHeaders: { [key: string]: string } = {}
    if (headers)
      for (const header of Object.getOwnPropertyNames(headers)) {
        const val = headers[header]
        if (!!val) customHeaders[header] = val
      }

    let encodedBody: string | undefined
    if (method === HttpRequestTypes.GET) {
      if (method === HttpRequestTypes.GET && body instanceof Object) url = RequestUtil.appendParametersToUrl(url, body)
    } else {
      if (typeof body !== "string") encodedBody = JSON.stringify(body)
      else encodedBody = body
    }

    const controller = abortController || new AbortController()

    let request: Promise<Response> = fetch(url, {
      headers: {
        ...this._getDefaultHeader(),
        ...customHeaders,
      },
      method,
      body: encodedBody,
      signal: controller.signal,
      ...additionalRequestConfig,
    })
    try {
      if (timeout > 0) {
        request = timeoutPromise(timeout, request)
      }

      const response = await request
      requestStats.success = response.ok
      requestStats.responseCode = response.status
      requestStats.durationMs = Date.now() - requestStats.firedAt
      if (response?.ok) {
        this.authStore.onSuccessfulRequest(preventMinivan)
        return response
      } else {
        if (response.status === 401) {
          throw Error("Nicht angemeldet")
        }

        throw response
      }
    } catch (e) {
      this.authStore.onUnsuccessfulRequest(requestStats, preventMinivan)
      throw e
    }
  }

  /**
   * Private method to get the API base url from the app settings.
   * @return {string}
   * @private
   */
  _getApiBase(): string {
    return this.apiBase + "/"
  }

  /**
   * Private method to build the auth headers from the app settings user.
   * @return {{Authorization: string, Content-Type: string}}
   * @private
   */
  _getDefaultHeader(): HttpHeaders {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
    }
    if (this.authStore.authToken) {
      headers["Authorization"] = "Bearer " + this.authStore.authToken
    }
    return headers
  }
}
