import React from "react"

type Props = {
  header: string
  children: React.ReactNode
}

const SettingContainer = ({ header, children }: Props) => (
  <div className="RadioGroupSetting">
    <div className="font-weight-bold">{header}</div>
    {children}
  </div>
)

export default SettingContainer
