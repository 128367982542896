import React from "react"
import { components as reactSelectComponents, ControlProps } from "react-select"
import { GroupBase, OptionProps } from "react-select"

export const IconControl =
  (icon: React.ReactNode) =>
  <
    OptionType extends OptionProps,
    IsMulti extends boolean,
    GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
  >({
    children,
    ...props
  }: ControlProps<OptionType, IsMulti, GroupType>) =>
    (
      <reactSelectComponents.Control {...props}>
        {icon}
        {children}
      </reactSelectComponents.Control>
    )
