import React, { useContext } from "react"
import { ZsbSorterConfig } from "../../model/store/ZsbSorterConfigStore"
import "./ZsbSorterConfigRow.scss"
import classNames from "classnames"
import { Input } from "reactstrap"
import { observer } from "mobx-react-lite"
import { ServiceManagerContext } from "../../../../AppServiceManager"
import ScanModeBadge from "../../components/ConfigSymbols/ScanModeBadge"
import SortForDateIcon from "../../components/ConfigSymbols/SortForDateIcon"
import EnforceVerifyIcon from "../../components/ConfigSymbols/EnforceVerifyIcon"

export const ZsbSorterConfigRowSkeleton: React.FC = () => (
  <tr className="zsb-sorter-config-row" key="loading-row">
    <td />
    <td>...</td>
    <td>...</td>
  </tr>
)

export const ZsbSorterConfigRowEmpty: React.FC = () => (
  <tr className="zsb-sorter-config-row" key="empty-row">
    <td colSpan={3} className="text-center">
      Wir konnten leider keine Scanner finden
    </td>
  </tr>
)

export const ZsbSorterConfigRowNothingFound: React.FC = () => (
  <tr className="zsb-sorter-config-row" key="empty-row">
    <td colSpan={3} className="text-center">
      Deine Suche ergab keine Treffer
    </td>
  </tr>
)

interface ZsbSorterConfigRowProps {
  config: ZsbSorterConfig
  isSelected: boolean
  onClick: (config: ZsbSorterConfig) => void
  changeSelection: (config: ZsbSorterConfig, nextState: boolean) => void
  search?: string
}

export const ZsbSorterConfigRow: React.FC<ZsbSorterConfigRowProps> = observer(
  ({ config, isSelected, onClick, changeSelection, search }: ZsbSorterConfigRowProps) => {
    const { defaultConfig } = useContext(ServiceManagerContext).zsbSorterConfigStore

    const onClickCheckbox = (e: React.MouseEvent) => {
      e.stopPropagation()
      changeSelection(config, !isSelected)
    }

    return (
      <tr className={classNames("ZsbSorterConfigRow", { isSelected })} onClick={() => onClick(config)}>
        <td className="checkbox-cell" onClick={onClickCheckbox}>
          <Input type="checkbox" checked={isSelected} readOnly />
        </td>
        <td>
          <div className="d-flex flex-column">
            <span className="font-weight-bold">{highlighted(config.label, search)}</span>
            <span className="d-lg-none text-muted">{highlighted(config.serialNo, search)}</span>
          </div>
        </td>
        <td className="d-none d-lg-table-cell">
          <span>{highlighted(config.serialNo, search)}</span>
        </td>
        <td className="d-none d-lg-table-cell">
          <span>{highlighted(config.deviceType, search)}</span>
        </td>
        <td>
          <div className="d-flex justify-content-between align-items-center">
            <div style={{ width: 40 }} className="d-flex justify-content-center">
              <SortForDateIcon
                setting={config.settings["SORT_FOR_DATE"]}
                defaultConfig={defaultConfig?.settings["SORT_FOR_DATE"]}
              />
            </div>
            <div style={{ width: 55 }} className="d-flex justify-content-center">
              <ScanModeBadge
                setting={config.settings["SCAN_MODE"]}
                defaultConfig={defaultConfig?.settings["SCAN_MODE"]}
              />
            </div>
            <div style={{ width: 25 }} className="d-flex justify-content-center">
              <EnforceVerifyIcon
                setting={config.settings["ENFORCE_VERIFY"]}
                defaultConfig={defaultConfig?.settings["ENFORCE_VERIFY"]}
              />
            </div>
          </div>
        </td>
      </tr>
    )
  }
)

const highlighted = (value?: string, lowerCaseSearch?: string): React.ReactNode | undefined => {
  if (!value) return undefined
  if (lowerCaseSearch === "") return value
  const regex = new RegExp(`(${lowerCaseSearch})`, "gi")
  const parts = value.split(regex)
  return (
    <span>
      {parts.map((part, index) => (
        <span key={`${part}_${index}`} className={classNames({ highlighted: part.toLowerCase() === lowerCaseSearch })}>
          {part}
        </span>
      ))}
    </span>
  )
}
