import { observer } from "mobx-react-lite"
import React, { useContext } from "react"
import { ServiceManagerContext } from "../../../../../AppServiceManager"
import { OrgUnit } from "../../../model/store/OrgUnitStore"
import { CityIcon } from "../Icons/Icons"
import Select from "../Select/Select"

const formatName = (orgUnit: OrgUnit) => `${orgUnit.name} (#${orgUnit.orgId})`

const SelectOrgUnit: React.FC = observer(() => {
  const { orgUnitStore } = useContext(ServiceManagerContext)

  return (
    <Select
      className="SelectOrgUnit"
      classNamePrefix="SelectOrgUnit"
      icon={<CityIcon style={{ paddingLeft: 7, marginRight: 10 }} />}
      value={orgUnitStore.selectedOrgUnit}
      placeholder={"Organisation auswählen..."}
      options={orgUnitStore.orgUnits}
      getOptionLabel={formatName}
      onChange={(o) => orgUnitStore.onSelectOrgUnit(o.orgId)}
    />
  )
})

export default SelectOrgUnit
