import React from "react"
import { Input, Label } from "reactstrap"
import classNames from "classnames"
import "./RadioBox.scss"

interface RadioBoxProps {
  className?: string
  checked: boolean
  label: string
  onChange: (nextState: boolean) => void
  disabled?: boolean
}

const RadioBox: React.FC<RadioBoxProps> = ({ className, checked, label, onChange, disabled }: RadioBoxProps) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const doNothing = () => {}

  const onClick = () => {
    if (disabled) return
    onChange(!checked)
  }

  return (
    <div className={classNames("RadioBox", className, { disabled })} onClick={onClick}>
      <Input type="radio" checked={checked} disabled={disabled} onChange={doNothing} />
      <Label className={classNames("ms-2 mb-0", { "text-muted": disabled })} disabled={disabled}>
        {label}
      </Label>
    </div>
  )
}

export default RadioBox
