import React from "react"
import { ClockIcon, TodayIcon, TomorrowIcon } from "../SharedComponents/Icons/Icons"
import ConfigSymbolContainer from "./ConfigSymbolContainer"
import { SettingProps } from "./SettingProps"

const SortForDateIcon: React.FC<SettingProps> = ({ setting, defaultConfig }: SettingProps) => {
  const isDefault = !setting?.value
  const currentSetting = setting?.value ?? defaultConfig?.value
  return (
    <ConfigSymbolContainer isDefault={isDefault}>
      {currentSetting === "CURRENT" && <TodayIcon />}
      {currentSetting === "NEXT" && <TomorrowIcon />}
      {(currentSetting ?? "").indexOf(":") !== -1 && <ClockIcon />}
    </ConfigSymbolContainer>
  )
}

export default SortForDateIcon
