import Keycloak from "keycloak-js"
import { action, makeAutoObservable } from "mobx"
import JwtTokenUtils from "../../../utils/JwtTokenUtils"
import { RequestStatistics } from "../../api/RequestStatistics"

const REFRESH_TOKEN = "common/auth/refreshToken"

interface AuthToken {
  resource_access?: {
    [key: string]: { roles: Array<string> }
  }
  orgunit_access?: Array<{
    orgId: string
    accessType?: string[]
  }>
  preferred_username?: string
  username?: string
  given_name?: string
  family_name?: string
  access_type?: string
}

interface KeycloakConfig {
  authServerUrl: string
  realm: string
  clientId: string
}

interface OrgUnitAccess {
  orgId: string
  accessType?: string[]
}
interface UserInfo {
  username?: string
  given_name?: string
  family_name?: string
  orgUnitAccess?: OrgUnitAccess[]
}

export default class AuthStore {
  private keycloak?: Keycloak.KeycloakInstance

  authToken?: string = undefined
  apiStatusOk = false
  nActiveRequests = 0
  disableLoadingAnimation = false
  currentPermission = ""

  get isLoggedIn() {
    return !!this.authToken
  }

  get apiIsLoading() {
    return this.nActiveRequests !== 0
  }

  get tokenPayload(): AuthToken | undefined {
    if (!this.authToken) {
      return undefined
    }
    return JwtTokenUtils.parseJwt(this.authToken)
  }

  get userProfileNames(): { firstName?: string; lastName?: string; username?: string } | undefined {
    const tokenPayload = this.tokenPayload
    if (!tokenPayload) return undefined

    return {
      firstName: tokenPayload.given_name,
      lastName: tokenPayload.family_name,
      username: tokenPayload.preferred_username,
    }
  }

  get userInfo(): UserInfo | undefined {
    const tokenPayload = this.tokenPayload
    if (!tokenPayload) return undefined
    const orgIdList = (tokenPayload.orgunit_access || []).map((item) => {
      return item
    })
    return {
      username: tokenPayload.preferred_username,
      given_name: tokenPayload.given_name,
      family_name: tokenPayload.family_name,
      orgUnitAccess: orgIdList,
    }
  }

  logout = () => {
    this.authToken = undefined
    window.localStorage.removeItem(REFRESH_TOKEN)
    if (this.keycloak) {
      this.keycloak.logout()
    }
  }

  updateToken() {
    this.authToken = this.keycloak?.token
    window.localStorage.setItem(REFRESH_TOKEN, this.keycloak?.refreshToken || "")
  }

  setApiStatusOk(yesno: boolean) {
    this.apiStatusOk = yesno
  }

  accountDetails = () => {
    this.keycloak?.accountManagement()
  }

  async refreshToken() {
    if (!this.keycloak) {
      return
    }
    const keycloak = this.keycloak
    if (!keycloak.token) {
      return
    }
    try {
      const refreshed = await keycloak.updateToken(30)
      if (refreshed) {
        this.updateToken()
        console.info("AuthStore", "Token was refreshed successfully")
      }
    } catch (e) {
      console.warn("AuthStore", "Failed to refresh the token, or the session has expired")
    }
  }

  onSuccessfulRequest(preventMinivan: boolean) {
    this.setApiStatusOk(true)
    if (!preventMinivan)
      setTimeout(
        action(() => this.nActiveRequests--),
        50
      )
  }

  onUnsuccessfulRequest(stats: RequestStatistics, preventMinivan: boolean) {
    if (!stats.responseCode || stats.responseCode >= 400) {
      this.setApiStatusOk(false)
    }
    if (!preventMinivan)
      setTimeout(
        action(() => {
          this.nActiveRequests--
        }),
        50
      )
  }

  async onStartedRequest(preventMinivan: boolean) {
    if (!preventMinivan) this.nActiveRequests++
    await this.refreshToken()
  }

  constructor(keycloakConfig: KeycloakConfig) {
    makeAutoObservable(this)

    if (keycloakConfig) {
      this.keycloak = Keycloak({
        url: keycloakConfig.authServerUrl,
        realm: keycloakConfig.realm,
        clientId: keycloakConfig.clientId,
      })
      this.keycloak
        .init({
          onLoad: "login-required",
          checkLoginIframe: false,
        })
        .then((authenticated) => {
          if (authenticated && this.keycloak?.token) {
            this.updateToken()
          }
          //this.setIsLoading(false)
        })
    }
  }
}
