import classnames from "classnames"
import React from "react"

interface IconProps {
  faClassName: string
  className?: string
  classNames?: string
  spinning?: boolean
  large?: boolean
  small?: boolean
  solid?: boolean
  regular?: boolean
  fixedWidth?: boolean
}

const Icon = ({
  faClassName,
  className,
  classNames,
  spinning = false,
  large = false,
  small = false,
  fixedWidth = true,
  regular = false,
  solid = !regular,
  ...props
}: IconProps) => {
  const combinedClassName = classnames(
    {
      fas: solid,
      far: regular,
      "fa-fw": fixedWidth,
      "fa-spin": spinning,
      "fa-lg": large,
      "fa-sm": small,
    },
    faClassName,
    classNames,
    className
  )
  return <i className={combinedClassName} {...props} />
}

export const CloseIcon = (props: any) => <Icon faClassName="fa-times" {...props} />
export const CityIcon = (props: any) => <Icon faClassName="fa-city" {...props} />
export const CaretUp = (props: any) => <Icon faClassName="fa-caret-up" {...props} />
export const CaretDown = (props: any) => <Icon faClassName="fa-caret-down" {...props} />
export const CogIcon = (props: any) => <Icon faClassName="fa-cog" {...props} />
export const QuestionIcon = (props: any) => (
  <Icon style={{ color: "#888" }} faClassName="fa-question-circle" {...props} />
)

export const TodayIcon = (props: any) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0H4V1H10V0H12V1H12.5H14V2V3.5V12.5V14H12.5H1.5H0V12.5V3.5V2V1H1.5H2V0ZM1.5 3.5H12.5V12.5H1.5V3.5Z"
      fill="#464646"
    />
    <rect x="3" y="6" width="4" height="4" fill="#464646" />
  </svg>
)

export const TomorrowIcon = (props: any) => (
  <svg width="39" height="14" viewBox="0 0 39 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0H4V1H10V0H12V1H12.5H14V2V3.5V12.5V14H12.5H1.5H0V12.5V3.5V2V1H1.5H2V0ZM1.5 3.5H12.5V12.5H1.5V3.5Z"
      fill="#464646"
    />
    <rect x="3" y="6" width="4" height="4" fill="#464646" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27 0H29V1H35V0H37V1H37.5H39V2V3.5V12.5V14H37.5H26.5H25V12.5V3.5V2V1H26.5H27V0ZM26.5 3.5H37.5V12.5H26.5V3.5Z"
      fill="#464646"
    />
    <rect x="32" y="6" width="4" height="4" fill="#464646" />
    <rect x="16" y="7" width="4" height="2" fill="#464646" />
    <path d="M23 8L20 11.4641V4.5359L23 8Z" fill="#464646" />
  </svg>
)

export const ClockIcon = (props: any) => (
  <svg width="39" height="14" viewBox="0 0 39 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0H4V1H10V0H12V1H12.5H14V2V3.5V12.5V14H12.5H1.5H0V12.5V3.5V2V1H1.5H2V0ZM1.5 3.5H12.5V12.5H1.5V3.5Z"
      fill="#464646"
    />
    <rect x="3" y="6" width="4" height="4" fill="#464646" />
    <ellipse stroke="#464646" strokeWidth="2" cx="20" cy="8" rx="3.50" ry="3.5" />
    <rect x="20" y="7" width="4" height="2" fill="#464646" />
    <rect x="20" y="4" width="2" height="4" fill="#464646" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27 0H29V1H35V0H37V1H37.5H39V2V3.5V12.5V14H37.5H26.5H25V12.5V3.5V2V1H26.5H27V0ZM26.5 3.5H37.5V12.5H26.5V3.5Z"
      fill="#464646"
    />
    <rect x="32" y="6" width="4" height="4" fill="#464646" />
  </svg>
)

export const ScanIcon = (props: any) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 6H6V0H0V6ZM2 2H4V4H2V2ZM8 0V6H14V0H8ZM12 4H10V2H12V4ZM0 14H6V8H0V14ZM2 10H4V12H2V10ZM13 8H14V12H11V11H10V14H8V8H11V9H13V8ZM13 13H14V14H13V13ZM11 13H12V14H11V13Z"
      fill="#464646"
    />
  </svg>
)

export const ScanDisabledIcon = (props: any) => (
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.95218 8H1V14H7V10.0173L3.95218 8ZM9 11.3411V14H11V12.6649L9 11.3411ZM12 13.3267V14H13V13.9886L12 13.3267ZM12.2206 6H15V0H9V3.86835L12.2206 6ZM7 2.54458V0H3.15556L7 2.54458ZM11 4H13V2H11V4ZM5 10H3V12H5V10Z"
      fill="#464646"
    />
    <rect x="1.38208" y="1" width="17.5323" height="2.5041" transform="rotate(33.5 1.38208 1)" fill="#464646" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10 12V14.0001H13.0001L10 12Z" fill="#464646" />
  </svg>
)

export const ScanCorrectIcon = (props: any) => (
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.93994 6H0V0H6V4.93994L4.93994 6ZM2 8H0V14H6V12.9083L5.0918 12H4H2V10V8ZM4 2H2V4H4V2ZM8 6V0H14V2.6188L10.6189 6H8ZM10 4H12V2H10V4Z"
      fill="#464646"
    />
    <rect
      x="9.20874"
      y="13.7884"
      width="2.96632"
      height="10.8765"
      transform="rotate(-135 9.20874 13.7884)"
      fill="#288711"
    />
    <rect
      x="9.20874"
      y="13.7884"
      width="6.92141"
      height="2.96632"
      transform="rotate(-135 9.20874 13.7884)"
      fill="#288711"
    />
  </svg>
)
