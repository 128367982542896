import React, { useContext } from "react"
import { Card, CardBody } from "reactstrap"
import { ServiceManagerContext } from "../../../../AppServiceManager"
import QuickSearchButton from "./QuickSearchButton"

interface Props {
  setSearchString: (next: string) => string
  setShowQuickSearch: React.Dispatch<React.SetStateAction<boolean>>
}

const QuickSearch = ({ setSearchString, setShowQuickSearch }: Props) => {
  const changeSearchWithSortMode = (mode: string) => {
    setSearchString("Sortiermodus=" + mode)
    setShowQuickSearch(false)
  }
  const { zsbSorterConfigStore } = useContext(ServiceManagerContext)

  const changeSearchWithSortDateMode = (mode: string) => {
    setSearchString("Sortierdatum=" + mode)
    setShowQuickSearch(false)
  }

  const sorterInModusSort = zsbSorterConfigStore.getBySortMode("SORTING").length
  const sorterInModusSeScan = zsbSorterConfigStore.getBySortMode("SE_SCAN").length
  const sorterInModusDemo = zsbSorterConfigStore.getBySortMode("DEMO").length

  const sortForDateCurrent = zsbSorterConfigStore.getBySortForDate("CURRENT").length
  const sortForDateNext = zsbSorterConfigStore.getBySortForDate("NEXT").length

  return (
    <div className="position-absolute mt-2 w-100" style={{ left: 0, top: "100%", zIndex: 10000 }}>
      <Card>
        <CardBody>
          <h5>Scanner im Sortiermodus</h5>
          <div className="d-flex mb-3">
            <QuickSearchButton
              changeSearchInput={() => changeSearchWithSortMode("Sortierung")}
              amount={sorterInModusSort}
              color="#0091CD"
              label="SORT"
            />
            <QuickSearchButton
              changeSearchInput={() => changeSearchWithSortMode("Sendungseingang")}
              amount={sorterInModusSeScan}
              color="#00CC0A"
              label="SE-Scan"
            />
            <QuickSearchButton
              changeSearchInput={() => changeSearchWithSortMode("DEMO-Modus")}
              amount={sorterInModusDemo}
              label="DEMO"
            />
          </div>
          <h5>Datumseinstellungen</h5>
          <div className="d-flex mb-3">
            <QuickSearchButton
              changeSearchInput={() => changeSearchWithSortDateMode("Heute")}
              amount={sortForDateCurrent}
              label="Heute"
            />
            <QuickSearchButton
              changeSearchInput={() => changeSearchWithSortDateMode("Nächster Zustelltag")}
              amount={sortForDateNext}
              label="Nächster Zustelltag"
            />
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default QuickSearch
