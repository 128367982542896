import React from "react"
import { CaretDown, CaretUp } from "../Icons/Icons"

export type SortDirection = "asc" | "desc"

interface SortCaretProps {
  direction: SortDirection
  showCaret: boolean
}

const SortCaret: React.FC<SortCaretProps> = ({ direction, showCaret }: SortCaretProps) => {
  const Caret = direction === "desc" ? CaretDown : CaretUp
  if (!showCaret) return <></>
  return <Caret style={{ position: "absolute", top: 3 }} />
}

export default SortCaret
