import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import getConfig from "./env"

ReactDOM.render(<App />, document.getElementById("root"))

// Changing page title if not production to make it easier to navigate between tabs
if (getConfig().appChannel !== "production")
  document.title = `${getConfig().appChannel.charAt(0).toUpperCase() + getConfig().appChannel.slice(1)} | ${
    document.title
  }`
