import { observer } from "mobx-react-lite"
import React, { useContext } from "react"
import { UncontrolledAlert } from "reactstrap"
import { ServiceManagerContext } from "../../../../AppServiceManager"
import { ToastMessage } from "../../model/store/UserNotificationStore"
import "./UserNotificationAlert.scss"

const UserNotificationAlert = () => {
  const context = useContext(ServiceManagerContext)

  const onClose = (id: string) => {
    context?.userNotificationStore.removeToastMessage(id)
  }

  const renderMessage = (message: React.ReactNode): React.ReactNode => {
    if (typeof message === "string") {
      return (
        <>
          {message.split("\n").map((line, index) => (
            <span key={index}>
              {index > 0 && <br />}
              {line}
            </span>
          ))}
        </>
      )
    } else {
      return message
    }
  }

  const toastMessages = (): ToastMessage[] => context?.userNotificationStore.toastMessages

  return (
    <div className="UserNotificationAlerts">
      <div>
        {toastMessages().map((message) => (
          <UncontrolledAlert
            key={message.id}
            color={message.color ?? "primary"}
            isOpen={true}
            toggle={() => onClose(message.id)}
            fade={false}
          >
            {message.message && (
              <div>
                <strong>{message.title}</strong>
                <br />
                {renderMessage(message.message)}
              </div>
            )}
            {!message.message && <div>{message.title}</div>}
          </UncontrolledAlert>
        ))}
        {context?.userNotificationStore.fetchRejectionSince !== undefined && (
          <div className="connection-alert">
            <div className="alert alert-danger">
              Verbindung zum Server fehlgeschlagen!
              <br />
              <small>Seit {Math.ceil((1 + context.userNotificationStore.fetchRejectionSince) / 60_000)} Minuten</small>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(UserNotificationAlert)
