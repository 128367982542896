/**
 * Extension of setInterval that uses a delay between the end of the first invocation and the start of the next one
 */
export default async (callback: () => Promise<unknown>, delay: number) => {
  const callbackInternal = async () => {
    await callback()
    setTimeout(callbackInternal, delay)
  }

  await callbackInternal()
}
