import React, { PropsWithChildren } from "react"
import { Table as ReactStrapTable, TableProps as ReactStrapTableProps } from "reactstrap"
import classnames from "classnames"
import "./Table.scss"

interface TableProps extends ReactStrapTableProps {
  className?: string
  fixedHeader?: boolean
  small?: boolean
  borderless?: boolean
}

const Table: React.FC<TableProps> = ({
  className,
  fixedHeader = true,
  small = false,
  borderless = false,
  children,
  ...props
}: PropsWithChildren<TableProps>) => {
  return (
    <ReactStrapTable
      className={classnames("CustomTable table table-hover", className, {
        "table-sm": small,
        "table-borderless": borderless,
        "table-fixed-header": fixedHeader,
      })}
      {...props}
    >
      {children}
    </ReactStrapTable>
  )
}

export default Table
