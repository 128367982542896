import React from "react"
import LoadingCar from "../../../../assets/loading-car"
import "./MinivanLoadingIndicator.scss"

type Props = {
  visible?: boolean
}
const MinivanLoadingIndicator: React.FC<Props> = ({ visible = true }: Props) => {
  return (
    <>
      {visible && (
        <figure className="MinivanLoadingIndicator">
          <div className="image-container">
            <LoadingCar />
          </div>
          <div className="shadow" />
          <span className="caption-image">Wir sind fast da.</span>
        </figure>
      )}
    </>
  )
}

export default React.memo(MinivanLoadingIndicator)
