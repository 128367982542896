import { Input, Label } from "reactstrap"
import React, { useContext, useEffect, useState } from "react"
import { ZsbSorterConfig } from "../../model/store/ZsbSorterConfigStore"
import { RadioBoxGroup } from "../../components/SharedComponents"
import { observer } from "mobx-react-lite"
import { ServiceManagerContext } from "../../../../AppServiceManager"
import fastCopy from "fast-copy"

interface SortForDateSettingProps {
  configurations: ZsbSorterConfig[]
  updateConfigurations: (configs: ZsbSorterConfig[]) => Promise<any>
}

const cutOffRegex = /^(?:[0-1][0-9]|[0-9]|[2][0-3]):[0-5][0-9]$/

const SortForDateSetting: React.FC<SortForDateSettingProps> = ({
  configurations,
  updateConfigurations,
}: SortForDateSettingProps) => {
  const { zsbSorterConfigStore, orgUnitStore } = useContext(ServiceManagerContext)

  const options = [
    { key: "SELF_CONTROLLED", label: "Wird am Gerät eingestellt" },
    { key: "CURRENT", label: "Heute" },
    { key: "NEXT", label: "Nächster Zustelltag" },
    { label: "Zeitabhängig" },
  ]

  const validCutOff = (text: string) => !!text.match(cutOffRegex)
  const getCurrentValue = (settingsKey: string): string | undefined => {
    const values = new Set<any | undefined>(configurations.map((it) => it.settings[settingsKey]?.value))
    if (values.size === 1)
      return (
        values.values().next().value ??
        zsbSorterConfigStore.defaultConfig?.settings[settingsKey]?.value ??
        "SELF_CONTROLLED"
      )
  }

  const currentValue = getCurrentValue("SORT_FOR_DATE")

  const [cutOffTime, setCutOffTime] = useState(currentValue && validCutOff(currentValue) ? currentValue : "12:00")
  const [showFormatHint, setShowFormatHint] = useState(false)
  const [timeoutHandle, setTimeoutHandle] = useState(undefined as any)

  useEffect(() => {
    setCutOffTime(currentValue && validCutOff(currentValue) ? currentValue : "12:00")
  }, [currentValue])

  const onChange = (value: any) => {
    if (timeoutHandle) {
      clearTimeout(timeoutHandle)
    }
    setTimeoutHandle(undefined)
    const next = fastCopy(configurations)
    next.forEach(
      (sorterConfig) =>
        (sorterConfig.settings["SORT_FOR_DATE"] = {
          key: "SORT_FOR_DATE",
          value,
          selfControlled: value === "SELF_CONTROLLED",
        })
    )
    updateConfigurations(next)
  }

  const onChangeCutOffTime = (newCutOffTime: string) => {
    if (validCutOff(newCutOffTime)) {
      if (timeoutHandle) {
        clearTimeout(timeoutHandle)
      }
      const t = setTimeout(() => {
        onChange(newCutOffTime)
      }, 1_000)
      setTimeoutHandle(t)
      setShowFormatHint(false)
    } else {
      setShowFormatHint(true)
    }
    setCutOffTime(newCutOffTime)
  }

  const inputRequired = (selectedValue: string | undefined) =>
    selectedValue !== "SELF_CONTROLLED" && selectedValue !== "CURRENT" && selectedValue !== "NEXT"

  return (
    <>
      <div className="my-2">
        Lege fest, ob und wann Sendungen anhand der Disposition des heutigen Tages oder des nächsten Zustelltages
        sortiert werden sollen. Die Sortierung für den nächsten Zustelltag ist z.B. sinnvoll, wenn Rückläufer am selben
        Tag von der Zustelltour wieder in das Sortierziel für den nächsten Tag gelegt werden (taggleicher Rücklauf),
        oder wenn an einem Depot Sendungen für den nächsten Tag schon vor Mitternacht sortiert werden.
      </div>
      <div className="mb-2">
        Beispiel: Wird eine Sendung am Samstag für den nächsten Zustelltag sortiert (Sortiermodus <em>Sortierung</em>),
        wird sie im ZSB-Cockpit in der für Montag disponierten Tour unter <em>Sortiert</em> angezeigt.
      </div>
      <div className="mb-2">
        Bitte beachte:
        <ul>
          <li>Der Sendungseingang (SE-Status) wird immer sofort gesetzt, unabhängig von dieser Einstellung.</li>
          <li>Die Sortierung für den nächsten Zustelltag ist erst ab 10:00 verfügbar.</li>
        </ul>
      </div>
      <RadioBoxGroup
        disabled={!orgUnitStore.hasWritePermission || zsbSorterConfigStore.isUpdateRequestPending}
        options={options}
        selected={currentValue}
        onChange={(nextKey) => {
          if (inputRequired(nextKey)) {
            const value = currentValue && validCutOff(currentValue) ? currentValue : "12:00"
            setCutOffTime(value)
            setShowFormatHint(false)
            onChange(value)
          } else {
            onChange(nextKey)
          }
        }}
        footerComponent={
          <>
            <Label for="sort_for_cut_off_time">Für heute sortieren bis</Label>
            <Input
              id="sort_for_cut_off_time"
              disabled={
                !inputRequired(currentValue) ||
                !orgUnitStore.hasWritePermission ||
                zsbSorterConfigStore.isUpdateRequestPending
              }
              onChange={(e) => onChangeCutOffTime(e.target.value)}
              value={cutOffTime}
            />
            {showFormatHint && <small className="text-danger">Bitte verwende das Format HH:MM für die Uhrzeit</small>}
          </>
        }
      />
    </>
  )
}

export default observer(SortForDateSetting)
