import { observer } from "mobx-react-lite"
import { Router } from "@reach/router"
import React from "react"
import "./App.scss"
import MinivanLoadingIndicator from "./modules/app/components/SharedComponents/LoadingSpinner/MinivanLoadingIndicator"
import SideNavigation from "./modules/app/components/SideNavigation/SideNavigation"
import TopNavigation from "./modules/app/components/TopNavigation/TopNavigation"
import { ServiceManagerContext, ServiceManagerInstance } from "./AppServiceManager"
import Home from "./modules/app/sites/home/Home"
import UserNotificationAlert from "./modules/app/components/UserNotificationAlert/UserNotificationAlert"
import UserInfoModal from "./modules/app/components/UserInfoModal/UserInfoModal"
import useUmamiScript from "./modules/utils/useUmamiScript"
import getConfig from "./env"
;(window as any).__SERVICEMANAGER = ServiceManagerInstance

const { umami } = getConfig()

const App = observer(() => {
  useUmamiScript(umami.url, umami.id)

  return (
    <ServiceManagerContext.Provider value={ServiceManagerInstance}>
      <div className="App">
        <MinivanLoadingIndicator visible={false} />
        <UserNotificationAlert />
        <UserInfoModal />
        <SideNavigation />
        <div className="d-flex flex-column flex-1 overflow-auto">
          <TopNavigation />
          <Router id="reach-router" className="flex-grow-1 d-flex overflow-hidden">
            <Home default />
            <Home path="/orgs/:orgId" />
          </Router>
        </div>
      </div>
    </ServiceManagerContext.Provider>
  )
})

export default App
