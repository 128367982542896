import { useEffect } from "react"

// sets umami script (analytics) depending on environment
const useUmamiScript = (url: string, websiteId: string) => {
  useEffect(() => {
    const umamiScript = document.createElement("script")

    umamiScript.setAttribute("defer", "defer")
    umamiScript.setAttribute("src", url)
    umamiScript.setAttribute("data-website-id", websiteId)

    document.body.appendChild(umamiScript)

    return () => {
      document.body.removeChild(umamiScript)
    }
  }, [])
}

export default useUmamiScript
