import React from "react"
import { FormGroup } from "reactstrap"
import { ZsbSorterConfig, ZsbSorterSettingKey } from "../../model/store/ZsbSorterConfigStore"
import { toJS } from "mobx"

type Props = {
  labelOn: JSX.Element
  labelOff: JSX.Element
  settingKey: ZsbSorterSettingKey
  configurations: ZsbSorterConfig[]
  updateConfigurations: (configs: ZsbSorterConfig[]) => Promise<any>
}

const ToggleSetting = ({ labelOn, labelOff, settingKey, configurations, updateConfigurations }: Props) => {
  const checked = configurations.every((c) => c.settings[settingKey]?.value)
  return (
    <FormGroup check className="my-1 ps-0">
      <div className="form-check form-switch">
        <input
          type="checkbox"
          className="form-check-input cursor-pointer"
          id="customSwitches"
          checked={checked}
          onChange={(e) =>
            updateConfigurations(
              configurations.map((config) => {
                const updatedConfig = toJS(config)
                updatedConfig.settings[settingKey] = {
                  key: settingKey,
                  value: e.target.checked,
                  selfControlled: false,
                }
                return updatedConfig
              })
            )
          }
        />
        <label className="custom-control-label cursor-pointer" htmlFor="customSwitches">
          {checked ? labelOn : labelOff}
        </label>
      </div>
    </FormGroup>
  )
}

export default ToggleSetting
